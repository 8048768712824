import {
  Container,
  Typography,
  Grid,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Parallax } from "react-scroll-parallax";
import Brightness1Icon from "@mui/icons-material/Brightness1";

import React, { Fragment, useCallback } from "react";
import MyButton from "./MyButton";
import TransitionComponent from "./TransitionComponent";
import InfiniteRollingLogos from "./InfiniteRollingLogos";
import { services2D } from "./Content2DArt";

const picsHeights = { xs: 380, sm: 680, md: 700, lg: 800, xl: 1000 };
const textWidth = { xs: 250, sm: 300, md: 330, lg: 400, xl: 500 };
const styles = {
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  card: {
    position: "relative",
  },
  overlay: {
    position: "absolute",
    textAlign: "center",
    fontSize: "40pt",
    fontWeight: "700",
    margin: "auto",
    top: "100px",
    width: "100%",
    color: "white",
    backgroundColor: "transparent",
  },
  p1: {
    maxWidth: 530,
    marginTop: 2,
    fontSize: 16,
    zIndex: 100,
  },
  body1: {
    // maxWidth: { xs: 600, md: 1000 },
    maxWidth: { xs: 300, sm: 400, md: 500, lg: 540, xl: 620 },
    fontSize: { xs: 12, sm: 13, md: 16, lg: 16, xl: 17 },
    margin: 0,
  },
  h1: {
    maxWidth: { xs: 350, sm: 400, md: 800, lg: 1000, xl: 1400 },
    margin: "auto",
    mt: { xs: 6, sm: 5, md: 4, lg: 7, xl: 8 },
    mb: { xs: 3, sm: 5, md: 2, lg: 7, xl: 8 },
    fontSize: { xs: 26, sm: 32, md: 40, lg: 50, xl: 60 },
  },
  h2: {
    maxWidth: { xs: 350, sm: 400, md: 800, lg: 1000, xl: 1400 },
    // margin: "auto",
    mt: 6,
    mb: 1,
    textTransform: "uppercase",
    fontSize: { xs: 30, sm: 36, md: 44, lg: 50, xl: 60 },
    // fontWeight: 400,
    color: "#fb8901",
    WebkitTextFillColor: "#fb8901",
  },
  container: {
    // maxWidth: { xs: 350, sm: 600, md: 800, lg: 1000, xl: 1400 },
    width: "90%",

    backgroundColor: "transparent",
    borderColor: "transparent",
    boxShadow: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
  },
};
function Content2DArtMobile(props) {
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    navigate("/portfolio", { replace: true });
    // document.getElementById("gg").scrollIntoView();

    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <Fragment>
      <Box sx={{ width: "100%" }}>
        <Parallax
          style={{ height: "100%", position: "absolute" }}
          translateX={["0px", "-60px"]}
          easing="easeInQuad"
        >
          <Box
            component={"img"}
            sx={{
              height: { xs: 460, sm: 680, md: 700, lg: 800, xl: 1000 },
              position: "absolute",
              zIndex: 0,
            }}
            src={"/assets/zid_mobile.png"}
          ></Box>
        </Parallax>
        <Grid
          overflow="hidden"
          container
          direction="row"
          rowSpacing={0}
          rowGap={0}
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            alignContent: "start",
            pt: { xs: 2, sm: 5, md: 10 },
          }}
          columnGap={{ xs: 2, sm: 5, md: 10 }}
        >
          <Box
            sx={{
              minWidth: { xs: 150, sm: 300, md: 330, lg: 400, xl: 500 },
              height: picsHeights,
              p: 2,
            }}
          >
            <Parallax
              style={{ height: "100%", position: "absolute" }}
              translateX={["0px", "80px"]}
              easing="easeInQuad"
            >
              <Box
                component={"img"}
                sx={{
                  height: picsHeights,
                  bottom: " 0%",
                }}
                src={"/assets/zombie_mobile.png"}
              ></Box>
            </Parallax>
          </Box>
          <Box
            sx={{
              pl: {
                xs: 10,
                sm: 14,
                md: "8%",
                lg: "12%",
                xl: "16%",
              },
              minWidth: textWidth,
            }}
          ></Box>
        </Grid>
      </Box>
      <InfiniteRollingLogos lessMD={true} />
      <Container sx={styles.container}>
        <TransitionComponent>
          <Box
            sx={{
              alignItems: "center",
              display: "inline-flex",
              zIndex: 1000,
            }}
          >
            <Typography
              variant="h1"
              sx={styles.h1}
              style={{ display: "inline-flex", zIndex: 100 }}
            >
              GAME ART PRODUCTION
            </Typography>
          </Box>
        </TransitionComponent>

        <Box sx={{ zIndex: 100, pb: 2 }}>
          <TransitionComponent>
            <Typography align="left" variant="body1" sx={styles.body1}>
              Nothing brings more attention than a high-quality stylised art
              that looks slick, unique, and ahead of time. Our team of experts
              will enhance your idea and turn it into the successful project.
            </Typography>
          </TransitionComponent>

          <TransitionComponent>
            <Typography
              variant="body1"
              sx={{
                mt: { xs: 3, sm: 3, md: 3, lg: 5, xl: 8 },
                fontSize: {
                  xs: 12,
                  sm: 16,
                  md: 18,
                  lg: 22,
                  xl: 24,
                },
                // fontFamily: ["Poppins", "sans-serif"].join(","),
                zIndex: 1000,
              }}
            >
              We offer a wide range of 2D and 3D art services:
            </Typography>
          </TransitionComponent>
          <List dense>
            {services2D.map((service, i) => {
              return (
                <TransitionComponent key={i} delay={300 + i * 60}>
                  <ListItem
                    sx={{
                      p: 0,
                    }}
                    key={i}
                  >
                    <ListItemIcon style={{ color: "#e369db" }} color="#e369db">
                      <Brightness1Icon
                        sx={{
                          fontSize: {
                            xs: "9px",
                            sm: "14px",
                            ms: "16px",
                            lg: "18px",
                            xl: "20px",
                          },
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={service}
                      primaryTypographyProps={{
                        ml: { xs: -5, sm: -4, md: -3, lg: -2, xl: -1 },
                        p: 0,
                        fontSize: {
                          xs: 12,
                          sm: 16,
                          md: 18,
                          lg: 22,
                          xl: 24,
                        },
                        variant: "body1",
                        // fontFamily: ["Poppins", "sans-serif"].join(","),
                      }}
                      sx={{ margin: 0 }}
                    />
                  </ListItem>
                </TransitionComponent>
              );
            })}
          </List>
          <TransitionComponent delay={700}>
            <MyButton
              color={"purple"}
              label={"portfolio"}
              handleClick={handleClick}
            />
          </TransitionComponent>
        </Box>
      </Container>
    </Fragment>
  );
}

export default Content2DArtMobile;
