import { Box, Typography } from "@mui/material";
import React from "react";

import "@splidejs/react-splide/css/skyblue";

// import PageStart from "../components/PageStart";
import { useNavigate } from "react-router-dom";
import MyFooter from "../components/MyFooter";
import NewFooter from "../components/NewFooter";
import ProgressiveImage from "react-progressive-graceful-image";
// import InfiniteRollingLogos from "../components/InfiniteRollingLogos";

function Portfolio(props) {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box pt={{ xs: 11, sm: 12, md: 17, lg: 18, xl: 20 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: props.lessSM ? "column" : "row",
            justifyContent: props.lessSM ? "space-between" : "space-around",
            alignItems: "center",
            width: "100%",
            height: {
              xs: "80vh",
              sm: "60vh",
              md: "70vh",
              lg: "80vh",
              xl: "80vh",
            },
            backgroundColor: "#050505",
          }}
        >
          <Box
            sx={{
              width: {
                xs: "75%",
                sm: "35%",
                md: "35%",
                lg: "35%",
                xl: "35%",
              },
              filter: "grayscale(1)",
              "&:hover": {
                width: {
                  xs: "78%",
                  sm: "37%",
                  md: "37%",
                  lg: "37%",
                  xl: "37%",
                },
                filter: "grayscale(0)",
              },
              transition: "width 1s ease, filter .6s ease",
            }}
            onClick={() => {
              navigate("/portfolio/project-893");
              props.onOpen();
            }}
            width={props.lessMD ? "75%" : "35%"}
          >
            <Typography
              sx={{ fontSize: { xs: 24, sm: 29, md: 34, lg: 36, xl: 38 } }}
              variant="h3"
            >
              Project 893
            </Typography>
            <Box>
              <ProgressiveImage
                src="/assets/portfolio/893 PROJECT/thumbnail_small.jpg"
                placeholder="/assets/portfolio/893 PROJECT/thumbnail_tiny.jpg"
              >
                {(src, loading) => (
                  <img
                    style={{ filter: loading ? "blur(10px)" : "blur(0)" }}
                    width="100%"
                    alt="project1"
                    src={src}
                  ></img>
                )}
              </ProgressiveImage>
            </Box>
          </Box>
          <Box
            sx={{
              width: {
                xs: "75%",
                sm: "35%",
                md: "35%",
                lg: "35%",
                xl: "35%",
              },
              filter: "grayscale(1)",
              "&:hover": {
                width: {
                  xs: "78%",
                  sm: "37%",
                  md: "37%",
                  lg: "37%",
                  xl: "37%",
                },
                filter: "grayscale(0)",
              },
              transition: "width 1s ease, filter .6s ease",
            }}
            onClick={() => {
              navigate("/portfolio/tinychef");
              props.onOpen();
            }}
          >
            <Typography
              sx={{ fontSize: { xs: 24, sm: 29, md: 34, lg: 36, xl: 38 } }}
              variant="h3"
            >
              Tiny Chef Run
            </Typography>
            <Box>
              <ProgressiveImage
                src="/assets/portfolio/TINY CHEF Renders/thumbnail_small.jpg"
                placeholder="/assets/portfolio/TINY CHEF Renders/thumbnail_tiny.jpg"
              >
                {(src, loading) => (
                  <img
                    style={{ filter: loading ? "blur(10px)" : "blur(0)" }}
                    width="100%"
                    alt="project2"
                    src={src}
                  ></img>
                )}
              </ProgressiveImage>
            </Box>
          </Box>
        </Box>
      </Box>
      {props.lessSM ? <MyFooter /> : <NewFooter />}
    </Box>
  );
}

export default Portfolio;
