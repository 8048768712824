import { Box, Typography } from "@mui/material";

export const TestimonialCard = (props) => {
  const styles = {
    body1: {
      fontSize: { xs: 10, sm: 13, md: 16, lg: 16, xl: 17 },
    },
    h4: {
      fontSize: { xs: 18, sm: 22, md: 24, lg: 28, xl: 32 },
      // mt: { xs: 5, sm: 6, md: 7, lg: 8, xl: 9 },
    },
    h5: {
      fontSize: { xs: 13, sm: 16, md: 18, lg: 22, xl: 24 },
    },
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: "#000000",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          height: { xs: 100, sm: 200, md: 300, lg: 250, xl: 300 },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography align="center" variant="h3" width="80%" sx={styles.h4}>
            {props.title}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ mx: 10 }}>
        <Typography align="center" variant="body1" sx={styles.body1}>
          {props.text}
        </Typography>
      </Box>
      <Box
        sx={{
          height: { xs: 100, sm: 200, md: 300, lg: 250, xl: 300 },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <Typography
            align="center"
            variant="h5"
            sx={{ ...styles.h5, color: "primary.main" }}
          >
            {props.name}
          </Typography>
          <Typography align="center" variant="body1" sx={styles.body1}>
            {props.company}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
