import { Box, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import Content2DArt from "../components/Content2DArt";
import Content2DArtMobile from "../components/Content2DArtMobile";
import CountUpLabel from "../components/CountUpLabel";
import InfiniteRollingLogos from "../components/InfiniteRollingLogos";
import MyFooter from "../components/MyFooter";
import MySeparator from "../components/MySeparator";
import NewFooter from "../components/NewFooter";
import TransitionComponent from "../components/TransitionComponent";
import { Testimonials } from "../components/Testimonials";
import PageStart from "../components/PageStart";

const Home = (props) => {
  const styles = {
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    card: {
      position: "relative",
    },
    container: {
      width: "90%",
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      zIndex: 1000,
    },
    container1: {
      width: "100%",
      margin: 0,
      zIndex: 1000,
    },
    body1: {
      mt: 0,
      mb: 0,
      width: "90%",
      fontSize: { xs: 12, sm: 13, md: 16, lg: 16, xl: 17 },
      zIndex: 1000,
      position: "relative",
    },
    h1: {
      margin: "auto",
      mt: 0,
      mb: 0,
      fontSize: { xs: 24, sm: 29, md: 36, lg: 45, xl: 54 },
      zIndex: 1000,
    },
    box: {
      width: { xs: 100, sm: 160, md: 200, lg: 250, xl: 300 },
      ml: { xs: 1, sm: 5, md: 8, lg: 10, xl: 12 },
      mr: { xs: 1, sm: 5, md: 6, lg: 10, xl: 12 },
      zIndex: 1000,
    },
  };
  const [imgLoaded, setImgLoaded] = useState(false);
  const [show, setShow] = useState(false);
  useEffect(() => {
    const onPageLoad = () => {
      setShow(true);
    };
    // Check if the page has already loaded
    if (document.readyState === "complete") {
      window.setTimeout(() => {
        setShow(true);
      }, 1500);
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  return (
    <Fragment>
      <PageStart
        img={props.lessMD ? "/assets/home_mobile.jpg" : "/assets/home.jpg"}
        placeholderImg="/assets/home_tiny.jpg"
        brightness={100}
      />
      {!props.loading ? (
        <Box
          sx={{
            display: !imgLoaded ? "visible" : "collapsed",
            transition: "opacity 1s ease-out 1s",
          }}
        >
          <InfiniteRollingLogos lessMD={props.lessMD} show={show} />

          <Box sx={{ width: "100%", position: "relative" }}>
            <TransitionComponent>
              <MySeparator
                home
                text="YOUR PARTNER IN STYLISED ART PRODUCTION"
                pt={{ xs: 4, sm: 6, md: 8, lg: 10, xl: 18 }}
                pb={{ xs: 4, sm: 6, md: 8, lg: 10, xl: 18 }}
              />
            </TransitionComponent>
            <TransitionComponent>
              <Typography
                variant="body1"
                paragraph
                align="center"
                sx={{
                  ...styles.body1,
                }}
              >
                Artistiggo Studio is a place that gathers the world's best
                talent to assist big companies in creating
                <br /> world-class projects that will entertain and fulfil
                millions of children and adults with its uniqueness, quality,
                and style.
                <br /> Artistiggo Studio offers a full range of 2D and 3D
                stylised art services and animation.
              </Typography>
            </TransitionComponent>

            <TransitionComponent noSlide>
              <Box
                sx={{
                  mt: "8%",
                  mb: "8%",
                  width: "100%",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <CountUpLabel
                  num={15}
                  text={"Titles we've worked on"}
                  timeout={150}
                />
                <CountUpLabel
                  num={20}
                  char={"+"}
                  text={"Number of Artists"}
                  timeout={100}
                />
                <CountUpLabel
                  num={15}
                  text={"YEARS OF EXPERIENCE"}
                  timeout={150}
                />
              </Box>
            </TransitionComponent>
          </Box>
          <InfiniteRollingLogos lessMD={props.lessMD} show={show} />
          {props.lessMD ? (
            <Fragment>
              <Content2DArtMobile show={show} />
              <InfiniteRollingLogos
                lessMD={props.lessMD}
                mt={{ xs: 3, sm: 5, md: 0, lg: 0, xl: 0 }}
                show={show}
              />
              <Testimonials />
            </Fragment>
          ) : (
            <Fragment>
              <Content2DArt show={show} />
              <InfiniteRollingLogos lessMD={props.lessMD} show={show} />
              <Testimonials />
            </Fragment>
          )}
          {props.lessSM ? <MyFooter /> : <NewFooter />}
        </Box>
      ) : (
        <Box sx={{ width: "100%", height: 5000 }}></Box>
      )}
    </Fragment>
  );
};

export default Home;
