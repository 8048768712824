import React, { useState, useEffect, Fragment } from "react";

import "./App.css";
import { Route, Routes } from "react-router-dom";
import MyAppBar from "./components/MyAppBar";
import Home from "./pages/Home";
import About from "./pages/About";
import Portfolio from "./pages/Portfolio";
import Contact from "./pages/Contact";
import { Box, Grid, Paper, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import PortfolioProjectMobile from "./pages/PortfolioProjectMobile";
import PortfolioProject from "./pages/PortfolioProject";

const arrayRange = (start, stop, step) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
  );
const project1 = {
  title: "TINY CHEF RUN",
  description: `Our team has covered the full production of Tiny Chef Run—an endless running mobile game. We've handled everything from creative direction to concept art, 3D modeling, and hand-painting of models.`,
};
const project2 = {
  title: "PROJECT 893",
  description: `Our team has crafted character concepts for the "Dress Up Game," allowing players to switch up their characters' outfits. We've also created a marketing illustration for the game.`,
};
const App = () => {
  const theme = useTheme();

  const lessMD = useMediaQuery(theme.breakpoints.down("md"));
  const lessSM = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(true);
  // const [disapear, setDisapear] = useState(false);
  const [show, setShow] = useState(false);
  useEffect(() => {
    const onPageLoad = () => {
      setShow(true);
    };
    // Check if the page has already loaded
    if (document.readyState === "complete") {
      window.setTimeout(() => {
        setShow(true);
      }, 100);
    } else {
      window.addEventListener("load", onPageLoad);
      setShow(false);
      setLoading(true);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);
  useEffect(() => {
    if (show) {
      window.setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  });
  if (loading) {
    window.scrollTo(0, 0);
  }
  return (
    <Fragment>
      <Grid
        container
        sx={{
          // visibility: !disapear ? "visible" : "collapse",
          visibility: loading ? "visible" : "collapse",
          opacity: loading ? "100%" : "0",
          transition: "opacity .3s ease-in, visibility .35s",
          bgcolor: "black",
          position: "absolute",
          width: "100%",
          height: "100%",
          backgroundColor: "#050505",
          alignContent: "center",
          alignItem: "center",
          justifyItems: "center",
          justifyContent: "center",
          zIndex: 20000,
        }}
      >
        <img
          width={"200px"}
          alt="Logo white"
          style={{
            zIndex: 200001,
            position: "absolute",
            alignSelf: "center",
            justifySelf: "center",
            opacity: !show ? "100%" : "0",

            transition: "opacity 1s ease-out, filter 1s linear",
          }}
          src="/assets/logo_white.png"
        />
        <img
          width={"200px"}
          alt="Logo orange"
          style={{
            zIndex: 200000,
            position: "absolute",
            alignSelf: "center",
            justifySelf: "center",
          }}
          src="/assets/logo_orange.png"
        />
      </Grid>
      <Box
        sx={{
          visibility: loading ? "visible" : "collapse",
          opacity: loading ? "100%" : "0",
          transition: "opacity .3s ease-in, visibility .35s",
          bgcolor: "black",
          position: "relative",
          width: "100%",
          height: loading ? 10000 : 0,
          backgroundColor: "#050505",
          alignContent: "center",
          alignItem: "center",
          justifyItems: "center",
          justifyContent: "center",
          zIndex: 1000,
        }}
      ></Box>

      <Paper
        sx={{
          width: "100%",
          visibility: loading ? "hidden" : "visible",
          borderRadius: 0,
          overflow: "hidden",
          backgroundColor: "#050505",
        }}
      >
        <Routes>
          <Route path="/" element={<MyAppBar />}>
            <Route
              index
              element={
                <Home lessMD={lessMD} lessSM={lessSM} loading={loading} />
              }
            />
            <Route
              path="/contact"
              element={
                <Contact lessMD={lessMD} lessSM={lessSM} loading={loading} />
              }
            />
            <Route
              path="/about"
              element={
                <About lessMD={lessMD} lessSM={lessSM} loading={loading} />
              }
            />
            <Route
              path="/portfolio"
              element={
                <Portfolio
                  lessMD={lessMD}
                  lessSM={lessSM}
                  loading={loading}
                  onOpen={() => setLoading(true)}
                  onLoaded={() => setLoading(false)}
                />
              }
            />
            <Route
              path="/portfolio/tinychef"
              element={
                lessMD ? (
                  <PortfolioProjectMobile
                    folder="TINY CHEF Renders"
                    title={project1.title}
                    description={project1.description}
                    column={arrayRange(1, 26, 1)}
                    lessSM={lessSM}
                  />
                ) : (
                  <PortfolioProject
                    folder="TINY CHEF Renders"
                    title={project1.title}
                    description={project1.description}
                    column1={arrayRange(1, 26, 2)}
                    column2={arrayRange(2, 26, 2)}
                  />
                )
              }
            />
            <Route
              path="/portfolio/project-893"
              element={
                lessMD ? (
                  <PortfolioProjectMobile
                    folder="893 PROJECT"
                    title={project2.title}
                    description={project2.description}
                    column={arrayRange(1, 14, 1)}
                    lessSM={lessSM}
                  />
                ) : (
                  <PortfolioProject
                    folder="893 PROJECT"
                    title={project2.title}
                    description={project2.description}
                    column1={arrayRange(1, 14, 2)}
                    column2={arrayRange(2, 14, 2)}
                  />
                )
              }
            />
          </Route>
        </Routes>
      </Paper>
    </Fragment>
  );
};

export default App;
