import { Box, TextField, Grid, Stack, Typography, Button } from "@mui/material";
import React, { Fragment, useRef, useState, useEffect } from "react";
import MyFooter from "../components/MyFooter";
import { styled } from "@mui/system";
import Email from "../hooks/smtp";
import NewFooter from "../components/NewFooter";
import TransitionComponent from "../components/TransitionComponent";

const StyledTextField = styled(TextField, {
  name: "StyledTextField",
})({
  "& label": {
    color: "gray",
    border: "0",
  },
  "& .MuiInputBase-root": {
    margin: 0,
    backgroundColor: "#333333",
    border: "none",
    color: "orange",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "0",
    },
    "&:hover fieldset": {
      borderColor: "orange",
    },
    "&.Mui-focused fieldset": {
      border: "2px solid orange",
    },
  },
});

function Contact(props) {
  const timeoutRef = useRef();
  const onPageLoad = () => {
    setShow(true);
  };

  // This will run one time after the component mounts
  useEffect(() => {
    // Check if the page has already loaded
    if (document.readyState === "complete") {
      timeoutRef.current = window.setTimeout(() => {
        setShow(true);
      }, 500);
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);
  const [show, setShow] = useState(false);
  const [sent, setSent] = useState(false);
  const [msg, setMsg] = useState("");
  const [subj, setSubj] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const styles = {
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    card: {
      position: "relative",
    },
    body1: {
      maxWidth: { xs: "88%", md: "90%" },
      fontSize: { xs: 12, sm: 14, md: 15, lg: 16, xl: 17 },
      zIndex: 1000,
    },
    textfield: {
      width: "inherit",
      mb: { xs: 1, sm: 1.2, md: 1.6, lg: 1.8, xl: 2 },
      fontSize: { xs: 12, sm: 14, md: 15, lg: 16, xl: 17 },

      visibility: sent ? "hidden" : "",
      "& .MuiInputBase-root": {
        ":hover": {
          bgcolor: "#404040",
        },
        transition: "background .3s linear",
      },
    },
    astronaut: {
      width: { xs: "95%", sm: "80%", md: "70%", lg: "60%", xl: "50%" },
      mt: { xs: 9, sm: 13, md: 15, lg: 15, xl: 15 },
      position: "absolute",
      border: "0px",
      filter: props.lessSM ? "brightness(20%)" : null,
      right: props.lessSM ? "0" : "5%",
    },
    overlay: {
      position: "absolute",
      textAlign: "center",
      fontSize: "40pt",
      fontWeight: "700",
      margin: "auto",
      top: "100px",
      width: "100%",
      color: "white",
      backgroundColor: "transparent",
    },
  };
  const handleClick = () => {
    Email.send({
      Host: process.env.REACT_APP_HOST,
      Username: process.env.REACT_APP_USERNAME,
      Password: process.env.REACT_APP_PASS,
      // To: process.env.REACT_APP_RECIEVER,
      To: "vagoo997@gmail.com",
      From: process.env.REACT_APP_SENDER,
      Subject: `${name} (${email}): ${subj}`,
      Body: msg,
    }).then((message) => {
      setSent(true);
    });
  };
  const onTypeMsg = (event) => {
    setMsg(event.target.value);
  };
  const onTypeSubj = (event) => {
    setSubj(event.target.value);
  };
  const onTypeName = (event) => {
    setName(event.target.value);
  };
  const onTypeEmail = (event) => {
    setEmail(event.target.value);
  };
  const multiline = useRef();

  return (
    <Fragment>
      {show ? (
        <Fragment>
          <Box
            sx={{
              opacity: show ? "100%" : 0,
              transition: "opacity 1s ease-out 1s",
            }}
          >
            <Stack spacing={0}>
              <TransitionComponent direction={"up"}>
                <Typography
                  paragraph
                  align="center"
                  variant="h2"
                  sx={{
                    mt: sent
                      ? { xs: 16, sm: 18, md: 20, lg: 26, xl: 36 }
                      : { xs: 12, sm: 14, md: 16, lg: 18, xl: 20 },
                    mb: { xs: 3, sm: 4, md: 6, lg: 8, xl: 10 },
                    fontSize: { xs: 30, sm: 40, md: 46, lg: 54, xl: 60 },
                  }}
                >
                  {!sent
                    ? "Let's create together!"
                    : "Your message has been sent!"}
                </Typography>
              </TransitionComponent>
              <TransitionComponent direction={"up"}>
                <Typography
                  variant="body1"
                  paragraph
                  align="center"
                  sx={styles.body1}
                >
                  {!sent
                    ? "Let's talk and explore how we can bring your idea to life and turn it into greatness."
                    : "Thank you for contacting us!"}
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  align="center"
                  sx={{
                    ...styles.body1,
                    mb: sent ? { xs: 5, sm: 12, md: 16, lg: 24, xl: 30 } : {},
                  }}
                >
                  {!sent
                    ? "Send us a message and we'll get back to you soon."
                    : "We will get back to you as soon as possible!"}
                </Typography>
              </TransitionComponent>
            </Stack>
            <Grid
              container
              direction="column"
              justifyContent={"center"}
              alignContent={"center"}
            >
              {sent ? null : (
                <Stack
                  sx={{
                    width: { xs: 300, sm: 400, md: 450, lg: 500, xl: 500 },
                    borderRadius: 5,
                    mt: { xs: 3, sm: 4, md: 5, lg: 7, xl: 8 },
                    mb: { xs: 3, sm: 4, md: 5, lg: 7, xl: 8 },
                  }}
                >
                  <Stack sx={{ visibility: sent ? "hidden" : "visible" }}>
                    <TransitionComponent direction={"up"}>
                      <StyledTextField
                        onChange={onTypeName}
                        sx={styles.textfield}
                        id="outlined-textarea"
                        label="Name"
                        variant="outlined"
                      />
                    </TransitionComponent>
                    <TransitionComponent direction={"up"}>
                      <StyledTextField
                        onChange={onTypeEmail}
                        sx={styles.textfield}
                        id="outlined-textarea"
                        label="Email"
                        variant="outlined"
                      />
                    </TransitionComponent>
                    <TransitionComponent direction={"up"}>
                      <StyledTextField
                        onChange={onTypeSubj}
                        sx={styles.textfield}
                        id="outlined-textarea"
                        label="Subject"
                        variant="outlined"
                      />
                    </TransitionComponent>
                    <TransitionComponent direction={"up"}>
                      <StyledTextField
                        onChange={onTypeMsg}
                        sx={styles.textfield}
                        rows={5}
                        multiline
                        ref={multiline}
                        label="Message"
                      />
                    </TransitionComponent>
                    <TransitionComponent direction={"up"}>
                      <Button
                        sx={{
                          width: "inherit",
                          backgroundColor: "#fb8901",
                          pt: 0.4,
                          pb: 0.2,
                          ":hover": {
                            bgcolor: "#faa948",
                            filter:
                              "drop-shadow(0 0mm 1mm rgba(251, 137, 1, 0.6))",

                            // color: "white",
                          },
                          transition: "filter .4s ease, background .4s ease",
                          // color: "#333333",
                          fontSize: { xs: 18, sm: 20, md: 22, lg: 24, xl: 26 },
                          fontFamily: ["Bebas Neue", "cursive"].join(","),
                          mt: 2,
                          visibility: sent ? "hidden" : "",
                          mb: sent ? 10 : 0,
                        }}
                        onClick={handleClick}
                        variant="contained"
                      >
                        Submit
                      </Button>
                    </TransitionComponent>
                  </Stack>
                </Stack>
              )}
            </Grid>
            {props.lessSM ? <MyFooter /> : <NewFooter />}
          </Box>
        </Fragment>
      ) : (
        <Box
          sx={{ width: "100%", height: 5000, backgroundColor: "#1A1A1A" }}
        ></Box>
      )}
    </Fragment>
  );
}

export default Contact;
