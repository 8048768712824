import React from "react";

import { styled } from "@mui/system";
import { Button } from "@mui/material";
function MyButton(props) {
  const MyButton = styled(Button, {
    name: "MyButton",
  })({
    "& .MuiButtonBase-root": {
      color: "black",
      backgroundColor: "black",
      // borderRadius: "50px",
    },
  });
  return (
    <MyButton
      sx={{
        m: 0,
        mt: { xs: 2, sm: 3, md: 2, lg: 4, xl: 5 },
        // mb: { xs: 2, sm: 3, md: 3, lg: 4, xl: 5 },
        bgcolor: props.color === "purple" ? "#e369db" : "#F18805",
        paddingX: { xs: 2.4, sm: 2.7, md: 3.3, lg: 3.6, xl: 3.8 },
        pt: { xs: 0.5, sm: 0.5, md: 0.65, lg: 0.6, xl: 0.6 },
        pb: { xs: 0.35, sm: 0.4, md: 0.5, lg: 0.5, xl: 0.5 },
        ":hover":
          props.color === "purple"
            ? {
                color: "white",
                bgcolor: "#f288eb",
                filter: "drop-shadow(0 0mm 1mm #e369db)",
              }
            : {
                color: "white",
                bgcolor: "#faa948",
                filter: "drop-shadow(0 0mm 1mm #fb8901)",
              },
        transition: "filter .4s ease, background .4s ease",
        color: "#fff",
        fontSize: {
          xs: 15,
          sm: 18,
          md: 21,
          lg: 24,
          xl: 25,
        },
        fontFamily: ["Bebas Neue", "cursive"].join(","),

        // margin: 1,
        zIndex: 5000,
      }}
      size="large"
      style={{ borderRadius: 25 }}
      onClick={props.handleClick}
      variant="contained"
    >
      {props.label}
    </MyButton>
  );
}

export default MyButton;
