import { Grid, keyframes, Box } from "@mui/material";
import { styled } from "@mui/material";
import React from "react";
import TransitionComponent from "./TransitionComponent";

const rollInLeft = keyframes`
  0% {
    left: 0%;
  }
  100% {
    left: -100%;
  }

`;
const Roller = styled(Grid)(({ roll }) => ({
  position: "relative",
  height: "130px",
  margin: 0,
  padding: 0,
  backgroundColor: "#000000",
  borderRadius: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  visibility: !roll && "hidden",
  animation: roll && `${rollInLeft} 12s infinite linear`,
  zIndex: 10000,
}));

function InfiniteRollingLogos(props) {
  const logos = [
    { name: "PudgyP", scale: "50" },
    { name: "Edumena", scale: "40" },
    { name: "Epic-Studios", scale: "40" },
    { name: "NF", scale: "80" },
    { name: "Amadeo", scale: "35" },
    { name: "Barajoun", scale: "50" },
    { name: "ColorDrop", scale: "85" },
    { name: "Coolman", scale: "75" },
  ];
  const logoImgAttributes = logos.map((logo) => {
    return {
      alt: logo.name,
      height: `${logo.scale * 0.9}%`,
      style: {
        margin: "auto",
      },
      src: `assets/Logos/${logo.name}${
        props.lessMD ? "_mobile" : "_mobile"
      }.png`,
      key: logo.name,
    };
  });
  const logoImgs = [
    logoImgAttributes.map((atributes) => (
      <img alt={atributes.alt} {...atributes} key={`${atributes.key}_0`} />
    )),
    logoImgAttributes.map((atributes) => (
      <img alt={atributes.alt} {...atributes} key={`${atributes.key}_1`} />
    )),
  ];
  return (
    <Box
      sx={{
        transition: "opacity 0.1s ease-out .1s",
        mt: props.mt ? props.mt : 0,
        mb: props.mb ? props.mb : 0,
        backgroundColor: "#050505",
        width: "100vw",
        zIndex: 500000,
      }}
    >
      {!props.noslide ? (
        <TransitionComponent>
          <Roller
            roll="true"
            overflow="hidden"
            container
            direction="row"
            spacing={5}
            style={{ width: "200vw" }}
            sx={{
              height: { xs: 40, sm: 50, md: 90, lg: 110, xl: 130 },
            }}
          >
            {logoImgs}
          </Roller>
        </TransitionComponent>
      ) : (
        <Box>
          <Roller
            roll="true"
            overflow="hidden"
            container
            direction="row"
            spacing={5}
            style={{ width: "200vw" }}
            sx={{
              height: { xs: 40, sm: 50, md: 90, lg: 110, xl: 130 },
              alignItems: "center",
            }}
          >
            {logoImgs}
          </Roller>
        </Box>
      )}
    </Box>
  );
}

export default InfiniteRollingLogos;
