import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

function CountUpLabel(props) {
  const styles = {
    box: {
      width: { xs: 100, sm: 160, md: 200, lg: 250, xl: 300 },
      ml: { xs: 1, sm: 5, md: 5, lg: 6, xl: 11 },
      mr: { xs: 1, sm: 5, md: 5, lg: 6, xl: 11 },
    },
    h1: {
      margin: "auto",
      mt: 0,
      mb: 0,
      fontSize: { xs: 46, sm: 70, md: 90, lg: 110, xl: 130 },
    },
    body1: {
      mt: 0,
      mb: 0,
      width: "90%",
      fontSize: { xs: 10, sm: 13, md: 16, lg: 18, xl: 20 },
    },
  };
  const [counter, setCounter] = useState(0);
  const num = props.num;
  const timeout = props.timeout;
  useEffect(() => {
    let timer;
    if (counter < num) {
      timer = setTimeout(() => {
        setCounter(counter + 1);
      }, timeout);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [counter, num, timeout]);
  return (
    <Box sx={styles.box}>
      <Typography
        variant="h1"
        paragraph
        align="center"
        sx={{ ...styles.h1, textTransform: "uppercase" }}
      >
        {`${counter}`}
        {props.char ? `${props.char}` : null}
      </Typography>
      <Typography
        variant="body1"
        paragraph
        align="center"
        sx={{ ...styles.body1, textTransform: "uppercase" }}
      >
        {props.text}
      </Typography>
    </Box>
  );
}

export default CountUpLabel;
