import React from "react";

import { Grid, Popover, Typography, Link, Icon, SvgIcon } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { LinkedIn } from "@mui/icons-material";

const styles = {
  icon: {
    color: "#979797",
    fontSize: { xs: 18, sm: 20, md: 24, lg: 28, xl: 32 },
    pt: { xs: 0.5, sm: 1, md: 1, lg: 1.5, xl: 2 },
    pr: { xs: 0.5, sm: 3, md: 3, lg: 3, xl: 4 },
    ":hover": {
      color: "#eeeeee",
    },
  },
};
const FooterLogos = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Grid
      container
      direction="row"
      justifyContent="start"
      sx={{ p: 0, width: "100%" }}
    >
      <Link
        target="_blank"
        rel="noreferrer"
        href="https://www.instagram.com/artistiggo_studio/"
      >
        <InstagramIcon sx={styles.icon} color={styles.icon.color} />
      </Link>
      <Link
        target="_blank"
        rel="noreferrer"
        href="https://artistiggostudio.artstation.com/"
      >
        <SvgIcon viewBox="0 0 42 32" sx={styles.icon} color={styles.icon.color}>
          <svg>
            <path d="M35 24.354c0-.704-.208-1.36-.565-1.91L22.937 2.525A3.536 3.536 0 0 0 19.813.652h-6.077l17.76 30.666 2.8-4.833c.553-.925.704-1.334.704-2.131Zm-35-.037 2.956 5.093h.001a3.536 3.536 0 0 0 3.157 1.938h19.624l-4.072-7.03H0ZM10.832 5.621l7.938 13.701H2.893l7.939-13.701Z" />
          </svg>
        </SvgIcon>
      </Link>
      <Link
        target="_blank"
        rel="noreferrer"
        href="https://www.linkedin.com/company/artistiggostudio/"
      >
        <LinkedIn sx={styles.icon} color={styles.icon.color} />
      </Link>
      <Link
        target="_blank"
        rel="noreferrer"
        href="https://twitter.com/artistiggo"
      >
        <TwitterIcon sx={styles.icon} color={styles.icon.color} />
      </Link>
      <Link
        aria-owns={"mouse-over-popover"}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        href="tel:+381637416717"
      >
        <PhoneIphoneIcon
          sx={styles.icon}
          color={styles.icon.color}
        ></PhoneIphoneIcon>
      </Link>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
          color: "#000",
          zIndex: 9999999,
          "& .MuiPopover-paper": {
            fontSize: { xs: 10, sm: 12, md: 14, lg: 16, xl: 20 },
            backgroundColor: "#000",
            p: 1,
            borderRadius: 2,
            filter: "drop-shadow(0 0mm 0.5mm rgba(241,136,5, 0.7))",
          },
        }}
        color="black"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        disableRestoreFocus
      >
        <Typography sx={{ color: "#fff" }}>+381637416717</Typography>
      </Popover>
    </Grid>
  );
};

export default FooterLogos;
