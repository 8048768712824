import React, { useRef } from "react";
import { Box, Slide } from "@mui/material";
import useIsInViewport from "../hooks/useIsInViewport";

function TransitionComponent(props) {
  const ref1 = useRef(null);
  const isInViewport1 = useIsInViewport(ref1);
  const boxStyle = props.noFade
    ? {
        height: !isInViewport1 ? 5000 : null,
        window: "inherit",
        zIndex: 5000,
      }
    : {
        height: !isInViewport1 ? 5000 : null,
        opacity: isInViewport1 ? "100%" : 0,
        transition: `opacity ${
          props.duration ? props.duration : 1500
        }ms ease-out ${props.delay ? props.delay : 500}ms`,
        window: "100%",
        zIndex: 5000,
      };

  return (
    <Box ref={ref1} sx={boxStyle}>
      {props.noSlide ? (
        <Box sx={{ width: "100%", zIndex: 1000 }}>
          {isInViewport1 ? props.children : null}
        </Box>
      ) : (
        <Slide
          direction={props.direction ? props.direction : "left"}
          in={isInViewport1}
          style={{
            transitionDelay: props.delay ? `${props.delay}ms` : "300ms",
            transitionDuration: props.duration ? `${props.duration}ms` : "1.3s",
            transition: "ease-in",
            zIndex: 1000,
          }}
          mountOnEnter
        >
          <Box sx={{ width: "100%" }}>
            {isInViewport1 ? props.children : null}
          </Box>
        </Slide>
      )}
    </Box>
  );
}

export default TransitionComponent;
