import { Typography, keyframes, CardMedia } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/system";
import React, { useCallback } from "react";
import CopyrightIcon from "@mui/icons-material/Copyright";
import FooterLogos from "./FooterLogos";
import FooterLinks from "./FooterLinks";
import MyButton from "./MyButton";
const rainbow = keyframes`
    0% {
      border-image: linear-gradient(to right, #fb8901, #fd8565, #ff7fe3) 20;
      filter: drop-shadow(0 0.5mm 2.5mm rgb(160, 0, 210));
    }
    25% {
      border-image: linear-gradient(to right, #fb8901, #ff7fe3, #fd8565) 20;
      filter: drop-shadow(0 -0.5mm 1mm rgb(160, 0, 210));
    }
    50% {
      border-image: linear-gradient(to right, #ff7fe3, #fb8901, #fd8565) 20;
      filter: drop-shadow(0 0.5mm 4mm crimson);
  
    }
    75% {
      border-image: linear-gradient(to right, #fb8901, #ff7fe3, #fd8565) 20;
      filter: drop-shadow(0 -0.5mm 1mm crimson);
  
    }
    100% {
      border-image: linear-gradient(to right, #fb8901, #fd8565, #ff7fe3) 20;
      filter: drop-shadow(0 0.6mm 2.5mm rgb(160, 0, 210));
  
    }
  
  `;

function NewFooter() {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate("/contact", { replace: true });
    window.scrollTo(0, 0);
  }, [navigate]);
  const styles = {
    footerText: {
      mb: { xs: 3, sm: 4, md: 3, lg: 2, xl: 4 },
      color: "rgb(220,220,220)",
      width: "100%",
      fontSize: { xs: 9, sm: 10, md: 12, lg: 13, xl: 14 },
    },
    icon: {
      color: "#979797",
      fontSize: { xs: 9, sm: 10, md: 12, lg: 13, xl: 14 },
      pt: { xs: 0, sm: 0.5, md: 0.5, lg: 0.5, xl: 0.5 },
      pr: { xs: 0.5, sm: 0.5, md: 0.7, lg: 1, xl: 1 },
    },
  };
  return (
    <Box
      sx={{
        borderRadius: 0,
        backgroundColor: "#000000",
        textAlign: "center",
        marginTop: 10,
        zIndex: 5000,
        position: "relative",
        pt: { xs: 2, sm: 3, md: 4, lg: 6, xl: 7 },
      }}
    >
      <Typography
        variant="h1"
        sx={{
          pb: { xs: 2, sm: 3, md: 4, lg: 6, xl: 7 },
          fontSize: { xs: 24, sm: 26, md: 28, lg: 32, xl: 36 },
        }}
      >
        LET'S CREATE TOGETHER
      </Typography>
      <Box
        variant="permanent"
        position="static"
        sx={{
          borderBottom: { xs: 2, sm: 2, md: 2, lg: 3, xl: 3 },
          animation: `${rainbow} 6s infinite linear`,
        }}
      />
      <Box
        sx={{
          mt: "4%",
          mb: "4%",
          width: "100%",
          display: "inline-flex",
          alignItems: "start",
          justifyContent: "start",
          pl: "5%",
        }}
      >
        <Box
          width={"45%"}
          paddingRight={"8%"}
          sx={{
            display: "inline-flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "start",
          }}
        >
          <Typography
            width={"100%"}
            align="left"
            variant="body1"
            sx={styles.footerText}
          >
            All company names, brand names, trademarks, logos, illustrations,
            videos and any other intellectual property (Intellectual Property)
            published on this website are the property of their respective
            owners.
          </Typography>
          <Typography
            paragraph
            align="left"
            variant="body1"
            sx={styles.footerText}
          >
            Any non-authorized usage of Intellectual Property is strictly
            prohibited and any violation will be prosecuted under the law.
          </Typography>

          <MyButton label={"Send us a message"} handleClick={handleClick} />
        </Box>
        <Box
          sx={{
            width: "32%",
            display: "inline-flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "start",
          }}
        >
          <FooterLinks />
          <CardMedia
            component={"img"}
            sx={{
              width: { xs: 80, sm: 75, md: 80, lg: 80, xl: 120 },
              mt: "2%",
              mb: "2%",
            }}
            image={"/assets/logo_orange.png"}
          />
          <Typography width={"100%"} align={"left"}>
            Follow us on
          </Typography>
          <FooterLogos />
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: { xs: 80, sm: 70, md: 70, lg: 80, xl: 120 },
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          borderTop: 1,
          borderColor: "#979797",
        }}
      >
        <Box sx={{ alignItems: "center" }}>
          <CopyrightIcon fontSize="small" color="#979797" sx={styles.icon} />
        </Box>
        <Box sx={{ alignItems: "center" }}>
          <Typography
            align="left"
            variant="body1"
            sx={{ ...styles.footerText, mb: 0 }}
          >
            Artistiggo Studio 2024 / All rights reserved
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default NewFooter;
