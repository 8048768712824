import { CardMedia, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ProgressiveImage from "react-progressive-graceful-image";

function PageStart(props) {
  const [show, setShow] = useState(false);
  // This will run one time after the component mounts
  useEffect(() => {
    const onPageLoad = () => {
      setShow(true);
    };
    // Check if the page has already loaded
    if (document.readyState === "complete") {
      window.setTimeout(() => {
        setShow(true);
      }, 100);
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);
  const styles = {
    card: {
      position: "relative",
      border: "0px",
    },
    h1: {
      opacity: show ? "100%" : 0,
      display: "inline-flex",
      visibility: show ? "visible" : "hidden",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      textAlign: "center",
      fontSize: { xs: 32, sm: 40, md: 50, lg: 60, xl: 70 },
      transition: "opacity 1s ease-out 300ms",
      margin: "auto",
      top: "40%",
      width: "100%",
      backgroundColor: "transparent",
    },
  };
  return (
    <Container
      disableGutters
      style={styles.card}
      maxWidth={false}
      sx={{
        borderColor: "transparent",
        boxShadow: "none",
      }}
    >
      <div
        style={{
          width: "100%",
          paddingBottom: show ? "0%" : "56%",
          backgroundColor: "#121212",
        }}
      >
        <ProgressiveImage src={props.img} placeholder={props.placeholderImg}>
          {(src, loading) => (
            <CardMedia
              component="img"
              image={src}
              sx={{
                boxSizing: "border-box",
                opacity: show ? "100%" : 0,
                transition: "opacity .6s ease",

                visibility: show ? "visible" : "hidden",
                paddingBottom: show ? "0%" : "56%",
                width: "100%",
                filter: `brightness(${
                  props.brightness ? props.brightness : 80
                }%)`,
              }}
            ></CardMedia>
          )}
        </ProgressiveImage>
      </div>
      <Typography variant="h1" sx={styles.h1}>
        {props.title}
      </Typography>
    </Container>
  );
}

export default PageStart;
