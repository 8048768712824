import {
  Grid,
  Popover,
  Typography,
  keyframes,
  Link,
  CardMedia,
  Icon,
  SvgIcon,
} from "@mui/material";

import { Box } from "@mui/system";
import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import CopyrightIcon from "@mui/icons-material/Copyright";
import FooterLinks from "./FooterLinks";
import { LinkedIn } from "@mui/icons-material";
const rainbow = keyframes`
  0% {
    border-image: linear-gradient(to right, #fb8901, #fd8565, #ff7fe3) 20;
    filter: drop-shadow(0 0.5mm 2.5mm rgb(160, 0, 210));
  }
  25% {
    border-image: linear-gradient(to right, #fb8901, #ff7fe3, #fd8565) 20;
    filter: drop-shadow(0 -0.5mm 1mm rgb(160, 0, 210));
  }
  50% {
    border-image: linear-gradient(to right, #ff7fe3, #fb8901, #fd8565) 20;
    filter: drop-shadow(0 0.5mm 4mm crimson);

  }
  75% {
    border-image: linear-gradient(to right, #fb8901, #ff7fe3, #fd8565) 20;
    filter: drop-shadow(0 -0.5mm 1mm crimson);

  }
  100% {
    border-image: linear-gradient(to right, #fb8901, #fd8565, #ff7fe3) 20;
    filter: drop-shadow(0 0.6mm 2.5mm rgb(160, 0, 210));

  }

`;
const styles = {
  icon: {
    color: "#979797",
    fontSize: { xs: 12, sm: 16, md: 17, lg: 18, xl: 20 },
    pt: { xs: 0, sm: 0.5, md: 0.5, lg: 0.5, xl: 0.5 },
    pr: { xs: 0.5, sm: 0.5, md: 0.7, lg: 1, xl: 1 },
  },
  icons: {
    color: "#979797",
    fontSize: { xs: 22, sm: 16, md: 17, lg: 18, xl: 20 },
    pt: { xs: 1, sm: 0.5, md: 0.5, lg: 0.5, xl: 0.5 },
    pr: { xs: 1, sm: 0.5, md: 0.7, lg: 1, xl: 1 },
    pl: { xs: 1, sm: 0.5, md: 0.7, lg: 1, xl: 1 },
    ":hover": {
      color: "#eeeeee",
    },
  },
  footerText: {
    mb: { xs: 3, sm: 4, md: 3, lg: 2, xl: 4 },
    color: "rgb(220,220,220)",
    width: "100%",
    fontSize: { xs: 9, sm: 10, md: 12, lg: 13, xl: 14 },
    maxWidth: { xs: 300, sm: 500, md: 12, lg: 13, xl: 14 },
  },
};
function MyFooter() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const openIG = () => {
    window.open(
      "https://www.instagram.com/artistiggo/",
      "_blank",
      "noreferrer"
    );
  };
  const openTW = () => {
    window.open("https://twitter.com/artistiggo", "_blank", "noreferrer");
  };
  return (
    <Box
      sx={{
        borderRadius: 0,
        backgroundColor: "#000000",
        textAlign: "center",
        marginTop: 10,
        zIndex: 5000,
        position: "relative",
      }}
    >
      <Typography
        variant="h1"
        sx={{
          pt: { xs: 2, sm: 3, md: 4, lg: 6, xl: 7 },
          pb: { xs: 2, sm: 3, md: 4, lg: 6, xl: 7 },
          fontSize: { xs: 24, sm: 26, md: 28, lg: 32, xl: 36 },
        }}
      >
        LET'S CREATE TOGETHER
      </Typography>
      <Box
        variant="permanent"
        position="static"
        sx={{
          borderBottom: { xs: 2, sm: 2, md: 2, lg: 3, xl: 3 },
          marginBottom: 6,
          animation: `${rainbow} 6s infinite linear`,
        }}
      />
      <Box
        sx={{
          width: "100%",
          display: "inline-flex",
          flexDirection: "column",
          alignItems: "center",

          justifyContent: "center",
        }}
      >
        <FooterLinks center small />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "inline-flex",
          flexDirection: "column",
          alignItems: "center",

          justifyContent: "center",
        }}
      >
        <CardMedia
          component={"img"}
          sx={{
            width: { xs: 80, sm: 70, md: 70, lg: 80, xl: 120 },
            mt: { xs: 5, sm: 70, md: 70, lg: 80, xl: 120 },
            mb: { xs: 5, sm: 70, md: 70, lg: 80, xl: 120 },
          }}
          image={"/assets/logo_orange.png"}
        />
        <Typography width={"100%"} align={"center"}>
          Follow us on
        </Typography>
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="center"
        sx={{ pb: { xs: 4, sm: 6, md: 7, lg: 8, xl: 10 } }}
      >
        <Link
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/artistiggo_studio/"
        >
          <InstagramIcon
            onClick={openIG}
            sx={styles.icons}
            color={styles.icon.color}
          />
        </Link>
        <Link
          target="_blank"
          rel="noreferrer"
          href="https://artistiggostudio.artstation.com/"
        >
          <SvgIcon viewBox="0 0 40 30" sx={styles.icons}>
            <path d="M35 24.354c0-.704-.208-1.36-.565-1.91L22.937 2.525A3.536 3.536 0 0 0 19.813.652h-6.077l17.76 30.666 2.8-4.833c.553-.925.704-1.334.704-2.131Zm-35-.037 2.956 5.093h.001a3.536 3.536 0 0 0 3.157 1.938h19.624l-4.072-7.03H0ZM10.832 5.621l7.938 13.701H2.893l7.939-13.701Z" />
          </SvgIcon>
        </Link>

        <Link
          target="_blank"
          rel="noreferrer"
          href="https://www.linkedin.com/company/artistiggostudio/"
        >
          <LinkedIn sx={styles.icons} color={styles.icon.color} />
        </Link>
        <Link
          target="_blank"
          rel="noreferrer"
          href="https://twitter.com/artistiggo"
        >
          <TwitterIcon
            onClick={openTW}
            sx={styles.icons}
            color={styles.icon.color}
          />
        </Link>
        <Link
          aria-owns={"mouse-over-popover"}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          href="tel:+381637416717"
        >
          <PhoneIphoneIcon
            sx={styles.icons}
            color={styles.icon.color}
          ></PhoneIphoneIcon>
        </Link>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
            color: "#000",
            zIndex: 9999999,
            "& .MuiPopover-paper": {
              fontSize: { xs: 10, sm: 12, md: 14, lg: 16, xl: 20 },
              backgroundColor: "#000",
              p: 1,
              borderRadius: 2,
              filter: "drop-shadow(0 -0mm 1mm rgba(160, 0, 210, 0.5))",
            },
          }}
          color="black"
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          disableRestoreFocus
        >
          <Typography sx={{ color: "#fff" }}>+381637416717</Typography>
        </Popover>
      </Grid>
      <Box width={"100%"} sx={{ width: "100%" }}>
        <Typography align="center" variant="body1" sx={styles.footerText}>
          All company names, brand names, trademarks, logos, illustrations,
          videos and any other intellectual property (Intellectual Property)
          published on this website are the property of their respective owners.
        </Typography>
        <Typography
          paragraph
          align="center"
          variant="body1"
          sx={styles.footerText}
        >
          Any non-authorized usage of Intellectual Property is strictly
          prohibited and any violation will be prosecuted under the law.
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: { xs: 80, sm: 70, md: 70, lg: 80, xl: 120 },
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          borderTop: 1,
          borderColor: "rgba(255,255,255,0.4)",
        }}
      >
        <Box sx={{ alignItems: "center" }}>
          <CopyrightIcon
            fontSize="small"
            color="rgb(220,220,220)"
            sx={styles.icon}
          />
        </Box>
        <Box sx={{ alignItems: "center" }}>
          <Typography
            variant="body1"
            sx={{
              color: "rgb(220,220,220)",
              padding: 0,
              fontSize: { xs: 10, sm: 12, md: 14, lg: 16, xl: 18 },
            }}
          >
            Artistiggo Studio 2024 / All rights reserved
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default MyFooter;
