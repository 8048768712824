import {
  Typography,
  Grid,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Parallax } from "react-scroll-parallax";
import Brightness1Icon from "@mui/icons-material/Brightness1";

import React, { useCallback } from "react";

import MyButton from "./MyButton";
import TransitionComponent from "./TransitionComponent";

const picsHeights = { xs: 460, sm: 680, md: 750, lg: 800, xl: 920 };
const textWidth = { xs: 250, sm: 300, md: 330, lg: 400, xl: 500 };
const styles = {
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  card: {
    position: "relative",
  },
  overlay: {
    position: "absolute",
    textAlign: "center",
    fontSize: "40pt",
    fontWeight: "700",
    margin: "auto",
    top: "100px",
    width: "100%",
    color: "white",
    backgroundColor: "transparent",
  },
  p1: {
    maxWidth: 530,
    marginTop: 2,
    fontSize: 16,
    zIndex: 100,
  },
  body1: {
    maxWidth: { xs: 250, sm: 300, md: 440, lg: 540, xl: 620 },
    fontSize: { xs: 12, sm: 13, md: 16, lg: 16, xl: 17 },
    ml: 0,
  },
  h1: {
    // color: "#F18805",
    maxWidth: { xs: 350, sm: 400, md: 800, lg: 1000, xl: 1400 },
    margin: "auto",
    mt: { xs: 3, sm: 5, md: 4, lg: 7, xl: 8 },
    mb: { xs: 3, sm: 5, md: 2, lg: 7, xl: 8 },
    fontSize: { xs: 26, sm: 32, md: 40, lg: 50, xl: 60 },
  },
  h2: {
    maxWidth: { xs: 350, sm: 400, md: 800, lg: 1000, xl: 1400 },
    mt: 6,
    mb: 1,
    textTransform: "uppercase",
    fontSize: { xs: 30, sm: 36, md: 44, lg: 50, xl: 60 },
    color: "#fb8901",
    WebkitTextFillColor: "#fb8901",
  },
};
export const services2D = [
  "Art Direction",
  "Concept art",
  "Character Design",
  "UI Design",
  "Illustration",
  "Animation",
  "3D Design",
];
const Content2DArt = (props) => {
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    navigate("/portfolio", { replace: true });

    window.scrollTo(0, 0);
  }, [navigate]);
  return (
    <Box sx={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignContent: "space-between",
        }}
      >
        <Box
          sx={{
            height: picsHeights,
            margin: "auto",
            ml: 0,
          }}
        >
          <Parallax
            style={{ height: "100%", position: "absolute" }}
            translateX={["-100px", "-160px"]}
            easing="easeInQuad"
          >
            <Box
              component={"img"}
              sx={{
                height: picsHeights,
                position: "absolute",
                zIndex: 0,
                opacity: "70%",
              }}
              src={"/assets/zid.png"}
            ></Box>
          </Parallax>
          <Grid
            overflow="hidden"
            container
            direction="row"
            rowSpacing={0}
            rowGap={0}
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              alignContent: "start",
            }}
          >
            <Parallax
              style={{ height: "100%", position: "absolute" }}
              translateX={["0px", "120px"]}
              easing="easeInQuad"
            >
              <Box
                component={"img"}
                sx={{
                  height: picsHeights,
                }}
                src={"/assets/zombie.png"}
              ></Box>
            </Parallax>
          </Grid>
        </Box>
        <Box
          sx={{
            pr: {
              xs: "8vw",
              sm: "8vw",
              md: "8vw",
              lg: "8vw",
              xl: "8vw",
            },
            minWidth: textWidth,
            zIndex: 1000,
            position: "relative",
          }}
        >
          <TransitionComponent>
            <Box
              sx={{
                alignItems: "center",
                display: "inline-flex",
                zIndex: 1000,
              }}
            >
              <Typography
                variant="h1"
                sx={styles.h1}
                style={{ display: "inline-flex", zIndex: 100 }}
              >
                GAME ART PRODUCTION
              </Typography>
            </Box>
          </TransitionComponent>
          <Box sx={{ zIndex: 100 }}>
            <TransitionComponent>
              <Typography align="left" variant="body1" sx={styles.body1}>
                Nothing brings more attention than a high-quality stylised art
                that looks slick, unique, and ahead of time. Our team of experts
                will enhance your idea and turn it into the successful project.
              </Typography>
            </TransitionComponent>

            <TransitionComponent>
              <Typography
                variant="body1"
                sx={{
                  mt: { xs: 3, sm: 3, md: 3, lg: 5, xl: 8 },
                  fontSize: {
                    xs: 16,
                    sm: 16,
                    md: 18,
                    lg: 22,
                    xl: 24,
                  },
                  // fontFamily: ["Poppins", "sans-serif"].join(","),
                  zIndex: 1000,
                }}
              >
                We offer a wide range of 2D and 3D art services:
              </Typography>
            </TransitionComponent>
            <List dense>
              {services2D.map((service, i) => {
                return (
                  <TransitionComponent key={i} delay={300 + i * 60}>
                    <ListItem
                      sx={{
                        p: 0,
                      }}
                      key={i}
                    >
                      <ListItemIcon
                        style={{ color: "#e369db" }}
                        color="#e369db"
                      >
                        <Brightness1Icon
                          sx={{
                            fontSize: {
                              xs: "12px",
                              sm: "14px",
                              ms: "16px",
                              lg: "18px",
                              xl: "20px",
                            },
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={service}
                        primaryTypographyProps={{
                          ml: { xs: -4, sm: -4, md: -3, lg: -2, xl: -1 },
                          p: 0,
                          fontSize: {
                            xs: 16,
                            sm: 16,
                            md: 18,
                            lg: 22,
                            xl: 24,
                          },
                          variant: "body1",
                          // fontFamily: ["Poppins", "sans-serif"].join(","),
                        }}
                        sx={{ margin: 0 }}
                      />
                    </ListItem>
                  </TransitionComponent>
                );
              })}
            </List>
            <TransitionComponent delay={700}>
              <MyButton
                color={"purple"}
                label={"portfolio"}
                handleClick={handleClick}
              />
            </TransitionComponent>
          </Box>
        </Box>
      </div>
    </Box>
  );
};

export default Content2DArt;
