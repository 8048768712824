import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import "yet-another-react-lightbox/styles.css";

import "@splidejs/react-splide/css/skyblue";

import MyFooter from "../components/MyFooter";
import NewFooter from "../components/NewFooter";

import Lightbox from "yet-another-react-lightbox";
import ProgressiveImage from "react-progressive-graceful-image";

const PortfolioProject = (props) => {
  const imgWidth = "46vw";
  const [index, setIndex] = useState(-1);
  const fullList = [...props.column1, ...props.column2];
  const paddingBetween = { xs: 4, sm: 7, md: 1, lg: 2, xl: 2 };

  fullList.sort((a, b) => a - b);
  return (
    <>
      <Box>
        <Box py={{ xs: 4, sm: 7, md: 17, lg: 18, xl: 20 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box width="93vw">
              <Typography variant="h1">{props.title}</Typography>
              <Box width={{ xs: 4, sm: 7, md: "80%", lg: "70%", xl: "60%" }}>
                <Typography variant="body1">{props.description}</Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              pt: { xs: 4, sm: 7, md: 1, lg: 1, xl: 1 },
              display: "flex",
              flexDirection: "row",
              height: "100%",
              width: "100%",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Box
              sx={{
                px: paddingBetween,
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              {props.column1.map((el) => (
                <Box
                  py={paddingBetween}
                  width={imgWidth}
                  key={el}
                  onClick={() => setIndex(el - 1)}
                >
                  <ProgressiveImage
                    src={`/assets/portfolio/${props.folder}/${el}_small.jpg`}
                    placeholder={`/assets/portfolio/${props.folder}/${el}_tiny.jpg`}
                  >
                    {(src, loading) => (
                      <img
                        width="100%"
                        alt={`project${el}`}
                        src={src}
                        style={{ filter: loading ? "blur(10px)" : "blur(0)" }}
                      ></img>
                    )}
                  </ProgressiveImage>
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                px: paddingBetween,
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              {props.column2.map((el) => (
                <Box
                  py={paddingBetween}
                  width={imgWidth}
                  key={el}
                  onClick={() => setIndex(el - 1)}
                >
                  <ProgressiveImage
                    src={`/assets/portfolio/${props.folder}/${el}_small.jpg`}
                    placeholder={`/assets/portfolio/${props.folder}/${el}_tiny.jpg`}
                  >
                    {(src, loading) => (
                      <img
                        width="100%"
                        alt={`project${el}`}
                        src={src}
                        style={{ filter: loading ? "blur(10px)" : "blur(0)" }}
                      ></img>
                    )}
                  </ProgressiveImage>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        {props.lessSM ? <MyFooter /> : <NewFooter />}
      </Box>

      <Lightbox
        styles={{
          root: {
            width: "100%",
            backgroundColor: "transparent",
            zIndex: 100002,
          },
          container: {
            backgroundColor: "rgba(5,5,5,0.8)",
          },
          toolbar: { display: "none" },
          slide: { padding: 100 },
        }}
        open={index >= 0}
        close={() => setIndex(-1)}
        controller={{ closeOnBackdropClick: true }}
        index={index}
        slides={fullList.map((el) => ({
          src: `/assets/portfolio/${props.folder}/${el}_show.jpg`,
          alt: `image ${el}`,
          width: 3840,
          height: 2560,
        }))}
      />
    </>
  );
};

export default PortfolioProject;
