import React from "react";

import { Link as RouterLink } from "react-router-dom";
import { Box, Link } from "@mui/material";

function FooterLinks(props) {
  const text = {
    noWrap: true,
    marginX: { xs: 1, sm: 1, md: 2, lg: 3, xl: 3 },
    fontSize: props.small
      ? { xs: 20, sm: 18, md: 20, lg: 26, xl: 32 }
      : { xs: 14, sm: 18.5, md: 26, lg: 32, xl: 36 },
    ":hover": {
      color: "#eeeeee",
    },
    transition: "color .4s ease",
    color: "#F18805",
  };
  return (
    <Box
      width={"100%"}
      sx={{
        display: "inline-flex",
        justifyContent: props.small ? "center" : null,
        minWidth: 600,
        pb: props.small ? 0 : null,
      }}
    >
      <Box>
        <Link
          noWrap={true}
          underline="none"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
          to="/"
          component={RouterLink}
          variant={"h1"}
          sx={{ ...text, marginX: 0, mr: text.marginX }}
        >
          HOME
        </Link>
      </Box>
      <Box>
        <Link
          noWrap={true}
          component={RouterLink}
          onClick={() => window.scrollTo(0, 0)}
          underline="none"
          to="/portfolio"
          variant={"h1"}
          sx={text}
        >
          OUR WORK
        </Link>
      </Box>
      <Box>
        <Link
          noWrap={true}
          component={RouterLink}
          onClick={() => window.scrollTo(0, 0)}
          underline="none"
          to="/about"
          variant={"h1"}
          sx={text}
        >
          ABOUT US
        </Link>
      </Box>
      <Box>
        <Link
          noWrap={true}
          component={RouterLink}
          onClick={() => window.scrollTo(0, 0)}
          underline="none"
          to="/contact"
          variant={"h1"}
          sx={text}
        >
          CONTACT
        </Link>
      </Box>
    </Box>
  );
}

export default FooterLinks;
