import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  CardMedia,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Fragment, useCallback, useState, useEffect } from "react";
import { Outlet, Link } from "react-router-dom";

const styles = {
  link: {
    textDecoration: "none",
    pl: 1,
    pr: 1,
    fontSize: { xs: 14, sm: 16, md: 18, lg: 20, xl: 24 },
  },
};
function MyAppBar() {
  const [headerOpacity, setHeaderOpacity] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    if (position < 50) {
      setHeaderOpacity(0);
    } else {
      setHeaderOpacity(0.8);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    navigate("/", { replace: true });

    window.scrollTo(0, 0);
  }, [navigate]);
  const margins = { xs: 1.6, sm: 2.4, md: 3.0, lg: 3.6, xl: 3.8 };
  return (
    <Fragment>
      <AppBar
        variant="permanent"
        position="fixed"
        color="secondary"
        sx={{
          top: 0,
          zIndex: 100000,
          backgroundColor: `rgba(5,5,5,${headerOpacity})`,
          transition: "background-color 0.5s",
          maxHeight: { sm: 200, m: 300, xl: 400 },
        }}
      >
        <Box
          sx={{
            pl: { xs: 3, sm: 6, md: 7, lg: 7, xl: 7 },
            pr: { xs: 3, sm: 6, md: 7, lg: 7, xl: 7 },
            width: "100",
          }}
        >
          <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
            <Box
              sx={{
                width: { xs: "50%", sm: "75%", md: "65%", xl: "65%" },
                justifyContent: "flex-start",
              }}
            >
              <CardMedia
                component={"img"}
                sx={{
                  marginTop: margins,
                  marginBottom: margins,
                  width: { xs: 54, sm: 62, md: 72, lg: 86, xl: 96 },
                }}
                onClick={handleClick}
                image={"/assets/logo_white.png"}
              />
            </Box>
            <Box
              sx={{
                width: { xs: "70%", sm: "45%", md: "50%", xl: "35%" },
                justifyContent: "flex-end",
              }}
            >
              <Container
                maxWidth="xl"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Box>
                  <Typography
                    variant="h6"
                    align="center"
                    noWrap={true}
                    component={Link}
                    color="inherit"
                    underline="none"
                    to="/"
                    sx={styles.link}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    HOME
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    noWrap={true}
                    variant="h6"
                    align="center"
                    component={Link}
                    color="inherit"
                    underline="none"
                    to="/portfolio"
                    sx={styles.link}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    OUR WORK
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    noWrap={true}
                    variant="h6"
                    align="center"
                    component={Link}
                    color="inherit"
                    underline="none"
                    to="/about"
                    sx={styles.link}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    ABOUT US
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    noWrap={true}
                    variant="h6"
                    align="center"
                    component={Link}
                    color="inherit"
                    underline="none"
                    to="/contact"
                    sx={styles.link}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    CONTACT
                  </Typography>
                </Box>
              </Container>
            </Box>
          </Toolbar>
        </Box>
      </AppBar>

      <Outlet />
    </Fragment>
  );
}
export default MyAppBar;
