import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./Testimonials.css";
import { Box } from "@mui/material";
import { TestimonialCard } from "./TestimonialCard";

const testimonials = [
  {
    title: "Beyond Expectations: Our Journey with Artistiggo Studio",
    text: "We had a vision to make our product stand out with fun and unique illustrations, and Artistiggo Studio completed the mission. They smoothly included our ideas in their workflow, making our collaboration enjoyable. Our ideas were not only enhanced but quickly incorporated and brought to life. Despite the tight deadline, Artistiggo team exceeded our expectations, delivering more than promised on time. Great service, talented and ambitious artists - definitely our go-to partners for future projects.",
    name: "Ivana Mitrovic",
    company: "Promote and Scale",
  },
  {
    title: "Turning Ideas into Art: Our Partnership with Artistiggo Studio",
    text: "We've partnered with Artistiggo Studio for 2 years now, and their work has consistently impressed us. Starting with small projects, we've gradually expanded the scope as their team grew. Initially, we collaborated closely with their team to define our style, but now we trust them entirely with creative direction. Their quality for the price is unbeatable. Fantastic team!",
    name: "Cameron Moulene",
    company: "Non Fungible Films",
  },
  {
    title: "Crafting Brilliance: Our story with Artistiggo Studio",
    text: "Collaboration between our agency and Artistiggo Studio has been a fun journey. We've worked together on a couple of animation projects, and their work is truly impressive. They never overlooked a single detail, and we're more than satisfied with their service.",
    name: "Marko Kazic",
    company: "Amadeo Agency",
  },
];

export const Testimonials = (props) => {
  return (
    <Box
      sx={{
        mt: { xs: 15, sm: 15, md: 15, lg: 15, xl: 15 },
        mb: { xs: 15, sm: 15, md: 15, lg: 15, xl: 15 },

        height: { xs: 400, sm: 420, md: 500, lg: 500, xl: 550 },
        width: "100%",
      }}
    >
      <Box
        sx={{
          m: "auto",
          width: {
            xs: "90%",
            sm: "80%",
            md: "70%",
            lg: "65%",
            xl: "50%",
          },
          height: "100%",
        }}
      >
        <Swiper
          navigation={true}
          className="mySwiper"
          autoplay={{
            delay: 6000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination, Navigation]}
          loop={true}
        >
          {testimonials.map((content, index) => (
            <SwiperSlide key={index}>
              <TestimonialCard {...content} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
};
