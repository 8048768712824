import { Box, Typography } from "@mui/material";
import React from "react";

import "@splidejs/react-splide/css/skyblue";

import MyFooter from "../components/MyFooter";
import NewFooter from "../components/NewFooter";
import ProgressiveImage from "react-progressive-graceful-image";

const PortfolioProjectMobile = (props) => {
  const imgWidth = "88vw";
  const paddingBetween = { xs: 0.7, sm: 1, md: 1 };

  return (
    <Box>
      <Box py={{ xs: 12, sm: 13 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box width="88vw">
            <Typography variant="h3">{props.title}</Typography>
            <Box width={{ xs: "95%", sm: "88%" }}>
              <Typography
                variant="body1"
                fontSize={{ xs: 12, sm: 13, md: 16, lg: 16, xl: 17 }}
              >
                {props.description}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              pt: { xs: 1, sm: 1 },
              px: paddingBetween,
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            {props.column.map((el) => (
              <Box py={paddingBetween} width={imgWidth} key={el}>
                <ProgressiveImage
                  src={`/assets/portfolio/${props.folder}/${el}_small.jpg`}
                  placeholder={`/assets/portfolio/${props.folder}/${el}_tiny.jpg`}
                >
                  {(src, loading) => (
                    <img
                      style={{ filter: loading ? "blur(10px)" : "blur(0)" }}
                      width="100%"
                      alt={`project${el}`}
                      src={src}
                    ></img>
                  )}
                </ProgressiveImage>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      {props.lessSM ? <MyFooter /> : <NewFooter />}
    </Box>
  );
};

export default PortfolioProjectMobile;
