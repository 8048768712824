import React from "react";
import { Typography, Box } from "@mui/material";
function MySeparator(props) {
  const styles = {
    div: {
      display: "inline-flex",
      position: "relative",
      left: "0%",
      alignItems: "center",
      justifyContent: "unsafe center",
      pt: props.pt,
      pb: props.pb,
      width: "100%",
      overflow: true,
      zIndex: 1000,
    },
  };
  return (
    <Box nowrap="true" sx={styles.div}>
      {/* <Box> */}
      {props.home ? null : (
        <Box
          sx={{
            backgroundColor: props.home
              ? "rgba(255,255,255,0.2)"
              : "rgba(255,255,255,0.3)",
            width: "100%",
            height: props.home ? "4px" : "2px",
          }}
        ></Box>
      )}
      <Typography
        variant="h1"
        nowrap="true"
        align="center"
        sx={{
          minWidth: props.home
            ? { xs: 360, sm: 500, md: 600, lg: 700, xl: 800 }
            : { xs: 100, sm: 150, md: 200, lg: 200, xl: 280 },
          fontSize: { xs: 24, sm: 29, md: 36, lg: 45, xl: 54 },
          color: props.home ? "#F18805" : "#fff",
          maxWidth: "90%",
        }}
      >
        {props.text}
      </Typography>
      {props.home ? null : (
        <Box
          sx={{
            backgroundColor: props.home
              ? "rgba(255,255,255,0.2)"
              : "rgba(255,255,255,0.3)",
            width: "100%",
            height: props.home ? "4px" : "2px",
          }}
        ></Box>
      )}
      {/* </Box> */}
    </Box>
  );
}

export default MySeparator;
