import { Box, Container, Grid, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import MyFooter from "../components/MyFooter";

import PageStart from "../components/PageStart";
import NewFooter from "../components/NewFooter";
import TransitionComponent from "../components/TransitionComponent";
import InfiniteRollingLogos from "../components/InfiniteRollingLogos";

function About(props) {
  const styles = {
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    card: {
      position: "relative",
      border: "0px",
    },
    overlay: {
      position: "absolute",
      textAlign: "center",
      fontSize: "40pt",
      margin: "auto",
      top: "100px",
      width: "100%",
      color: "white",
      backgroundColor: "transparent",
    },
    container: {
      width: "90%",
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      zIndex: 5000,
    },
    container1: {
      backgroundColor: "transparent",
      borderColor: "transparent",
      boxShadow: "none",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      zIndex: 5000,
    },
    astronaut: {
      width: props.lessMD ? "100%" : "90%",
      mt: { xs: 48, sm: 50, md: 80, lg: 80, xl: 90 },
      position: "absolute",
      filter: props.lessMD ? "brightness(20%)" : null,

      border: "0px",
      right: props.lessMD ? "0" : "5%",
      zIndex: 1,
    },
    demon: {
      width: props.lessMD ? "100%" : "90%",
      mt: { xs: 188, sm: 190, md: 180, lg: 180, xl: 190 },
      position: "absolute",
      filter: props.lessMD ? "brightness(20%)" : null,

      border: "0px",
      right: props.lessMD ? "0" : "5%",
      zIndex: 1,
    },
    body1: {
      fontSize: { xs: 12, sm: 13, md: 16, lg: 16, xl: 17 },
      zIndex: 1000,
    },
    h1: {
      maxWidth: { xs: 350, sm: 400, md: 800, lg: 1000, xl: 1400 },
      margin: "auto",
      mt: { xs: 3, sm: 5, md: 4, lg: 7, xl: 8 },
      mb: { xs: 3, sm: 5, md: 2, lg: 7, xl: 8 },
      fontSize: { xs: 24, sm: 29, md: 36, lg: 45, xl: 54 },
      zIndex: 1000,
    },
    h2: {
      maxWidth: { xs: 350, sm: 400, md: 800, lg: 1000, xl: 1400 },
      mt: 6,
      mb: 1,
      textTransform: "uppercase",
      fontSize: { xs: 24, sm: 29, md: 36, lg: 45, xl: 54 },
      color: "#fb8901",
      WebkitTextFillColor: "#fb8901",
      zIndex: 1000,
    },
  };
  const [show, setShow] = useState(false);
  useEffect(() => {
    const onPageLoad = () => {
      setShow(true);
    };
    // Check if the page has already loaded
    if (document.readyState === "complete") {
      window.setTimeout(() => {
        setShow(true);
      }, 500);
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  return (
    <Fragment>
      <PageStart
        title="ABOUT US"
        img={
          props.lessSM
            ? "/assets/websiteaboutus_mobile.jpg"
            : "/assets/websiteaboutus.jpg"
        }
        placeholderImg="/assets/websiteaboutus_tiny.jpg"
        brightness={70}
      />
      {show ? (
        <Fragment>
          <InfiniteRollingLogos
            lessMD={props.lessMD}
            mb={{ xs: 4, sm: 5, md: 8, lg: 10, xl: 12 }}
            show={show}
          />
          <Box
            sx={{
              visibility: show ? "visible" : "hidden",
            }}
          >
            <Grid
              container
              direction="column"
              justifyContent={"center"}
              disablegutters="true"
              sx={styles.container1}
            >
              <TransitionComponent delay={500}>
                <Typography
                  paragraph
                  align="center"
                  variant="h2"
                  sx={styles.h1}
                >
                  OUR MISSION
                </Typography>
              </TransitionComponent>
              <Container sx={styles.container}>
                <TransitionComponent delay={500}>
                  <Typography
                    variant="body1"
                    paragraph
                    align="center"
                    sx={styles.body1}
                  >
                    Artistiggo Studio is a place that gathers the world's best
                    talent to assist big companies in creating world-class
                    projects that will entertain and fulfill millions of
                    children and adults with their uniqueness, quality, and
                    style.
                  </Typography>
                </TransitionComponent>
                <TransitionComponent delay={500}>
                  <Typography
                    variant="body1"
                    paragraph
                    align="center"
                    sx={styles.body1}
                  >
                    We strive to turn any project into a fruitful journey where
                    both parties enjoy the process from Day 1.
                  </Typography>
                </TransitionComponent>
                <TransitionComponent delay={500}>
                  <Typography
                    paragraph
                    align="center"
                    variant="h2"
                    sx={styles.h1}
                  >
                    HOW DO WE ACHIEVE IT?
                  </Typography>
                </TransitionComponent>
              </Container>
            </Grid>
            <Container sx={styles.container}>
              <TransitionComponent delay={500}>
                <Typography variant="h2" sx={styles.h2} paragraph align="left">
                  COMMITMENT
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  align="left"
                  sx={styles.body1}
                >
                  We don’t just produce art - we build relationships. We are
                  your long-term partners, and our goal is to produce iconic
                  games and be engaged in them for a long time. To achieve that,
                  we carefully pick who we work with, and when we do, we nurture
                  great relationships and foster mutual growth.
                </Typography>
              </TransitionComponent>
            </Container>
            <Container sx={styles.container}>
              <TransitionComponent delay={500}>
                <Typography variant="h2" sx={styles.h2} paragraph align="left">
                  VALUE
                </Typography>
                <Typography
                  variant="body1"
                  sx={styles.body1}
                  paragraph
                  align="left"
                >
                  By carefully selecting our partners, we build a path that
                  allows us to sustain a sharp focus on fewer projects, ensuring
                  they are awesome, instead of taking on everything and ending
                  up with mediocrity.
                </Typography>
              </TransitionComponent>
            </Container>
            <Container sx={styles.container}>
              <TransitionComponent delay={500}>
                <Typography variant="h2" sx={styles.h2} paragraph align="left">
                  UNIQUENESS
                </Typography>
                <Typography
                  style={{ color: "#ffffff" }}
                  variant="body1"
                  paragraph
                  align="left"
                  sx={styles.body1}
                >
                  We believe players become attached to unique and distinctive
                  games. With your feedback, we'll experiment and push the
                  boundaries of creativity until we create something truly
                  incredible.
                </Typography>
              </TransitionComponent>
            </Container>
            <Container sx={styles.container}>
              <TransitionComponent delay={500}>
                <Typography variant="h2" sx={styles.h2} paragraph align="left">
                  STRUCTURE
                </Typography>
                <Typography
                  style={{ color: "#ffffff" }}
                  variant="body1"
                  paragraph
                  align="left"
                  sx={styles.body1}
                >
                  Our hybrid studio allows us to attract the best talent from
                  all over the world, and our workflow enables us to deliver
                  premium art in the shortest period.
                </Typography>
              </TransitionComponent>
            </Container>
            <Container sx={styles.container}>
              <TransitionComponent delay={500}>
                <Typography variant="h2" sx={styles.h2} paragraph align="left">
                  APPROACH
                </Typography>
                <Typography
                  style={{ color: "#ffffff" }}
                  variant="body1"
                  paragraph
                  align="left"
                  sx={{ ...styles.body1, mb: 12 }}
                >
                  Our goal is to inspire and elevate your ideas while delivering
                  the best art possible. We like to think outside the box, so
                  please don't mind us if we come up with something totally new
                  and unseen. Having contributed to numerous projects in both
                  the gaming and Web3 industries, our new mission is to develop
                  art for the next worldwide game.
                </Typography>
              </TransitionComponent>
            </Container>
            {props.lessSM ? <MyFooter /> : <NewFooter />}
          </Box>
        </Fragment>
      ) : (
        <Box sx={{ width: "100%", height: 5000 }}></Box>
      )}
    </Fragment>
  );
}

export default About;
